import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass, NgOptimizedImage} from '@angular/common';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'category-card',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgClass
  ],
  templateUrl: './category-card.component.html'
})
export class CategoryCardComponent {
@Input() title:string;
@Input() imagePath:string;
@Input() selected:boolean = false;
@Output() action : EventEmitter<any> = new EventEmitter()
baseImageUrl = environment.baseImageUrl

  emitAction() {
  this.action.emit();
  }
}
