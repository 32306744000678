<button
  mat-fab
  id="floating-button"
  [matBadge]="count"
  [matBadgeHidden]="!count"
  matBadgePosition="before"
  [disabled]="disabled"
  (click)="emitAction()">
  <mat-icon>
    {{icon ? icon : 'add'}}
  </mat-icon>
</button>
