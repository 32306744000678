<div class="view_icons_container">
  <button mat-mini-fab color="primary" (click)="_view.gridView()">
    <mat-icon [ngClass]="{'selectedView': _view.grid_view}" >
      apps
    </mat-icon>
  </button>
  <button mat-mini-fab color="primary" class="m-x-4" (click)="_view.listView()">
    <mat-icon [ngClass]="{'selectedView': _view.list_view}" >
      view_headline
    </mat-icon>
  </button>
</div>
