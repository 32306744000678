import {Component, Input} from '@angular/core';
import {MatCard, MatCardContent} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';
import {TranslatePipe} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'screens-cards-container',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatIcon,
    TranslatePipe,
    RouterLink
  ],
  templateUrl: './screens-cards-container.component.html',
  styleUrl: './screens-cards-container.component.scss'
})
export class ScreensCardsContainerComponent {
@Input() cards:any;
}
