import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButton, MatIconButton} from '@angular/material/button';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'screen-dialog',
  standalone: true,
  imports: [
    TranslatePipe,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    ReactiveFormsModule,
    MatIconButton,
    MatDialogClose,
    MatIcon,
  ],
  templateUrl: './screen-dialog.component.html',
  styleUrl: './screen-dialog.component.scss'
})
export class ScreenDialogComponent {
  @Input() title: string;
  @Input() save_action_text: string = 'COMMON.SAVE';
  @Input() primaryActionLabel: string;
  @Input() accentActionLabel: string;
  @Input() dialogContentWidth: number = 700;
  @Input() dialogContentMinWidth: number = 460;
  @Input() disableCondition: boolean = false;
  @Input() twoActions: boolean = false;
  @Output() primaryAction = new EventEmitter<void>();
  @Output() accentAction = new EventEmitter<void>();
  @Output() submitDialog = new EventEmitter<void>();
  @Output() closeDialog = new EventEmitter<void>();
  @Input() noAction: boolean = false;
  constructor(
    public _translate: TranslateService,
  ) {
  }

  onSubmit() {
    this.submitDialog.emit();
  }
  onPrimaryAction() {
    this.primaryAction.emit();
  }
  onAccentAction() {
    this.accentAction.emit();
  }

  onCancel() {
    this.closeDialog.emit();
  }
}
