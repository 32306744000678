import { Injectable } from '@angular/core';
import {UserTypesRolesEnum} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {
  private userTypes: any[];

  constructor() {
    this.userTypes = JSON.parse(localStorage.getItem('CURRENT_USER'))?.userTypes;
  }

  /**
   * @check if the current user is admin
   * @return true or false
   */
  isAdmin(): boolean {
    return this.userTypes?.some(type => type.code === UserTypesRolesEnum.admin) ?? false;
  }

  /**
   * @check if the current user is customer
   * @return true or false
   */
  isCustomer(): boolean {
    return this.userTypes?.some(type => type.code === UserTypesRolesEnum.customer) ?? false;
  }

  /**
   * @check if the current user is consumer
   * @return true or false
   */
  isConsumer(): boolean {
    return this.userTypes?.some(type => type.code === UserTypesRolesEnum.consumer) ?? false;
  }

  /**
   * @check if the current user is accountManager
   * @return true or false
   */
  isAccountManager(): boolean {
    return this.userTypes?.some(type => type.code === UserTypesRolesEnum.accountManager) ?? false;
  }

  /**
   * @check if the current user is employee
   * @return true or false
   */
  isEmployee(): boolean {
    return this.userTypes?.some(type => type.code === UserTypesRolesEnum.employee) ?? false;
  }

  /**
   * @check if the current user is supervisor
   * @return true or false
   */
  isSupervisor(): boolean {
    return this.userTypes?.some(type => type.code === UserTypesRolesEnum.supervisor) ?? false;
  }


  /**
   * @check if the current user is backoffice
   * @return true or false
   */
  isBackOffice(): boolean {
    return this.userTypes?.some(type => type.code === UserTypesRolesEnum.backoffice) ?? false;
  }

}
