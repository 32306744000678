import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  constructor(public _translate: TranslateService) {}

  ngOnInit(): void {}

  changeLanguage(lang) {
    // this._translate.use(lang);
    localStorage.setItem('LANG', lang);
    // get ar config
    // get en config
    // set current lng to config
    window.location.reload();
  }
}
