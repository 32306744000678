import {Component, inject, OnInit} from '@angular/core';
import {ConnectionService, ConnectionState} from 'ng-connection-service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {SnackService} from '../../snack/snack.service';

@Component({
  selector: 'wifi-connection',
  templateUrl: './wifi-connection.component.html',
  styleUrls: ['./wifi-connection.component.scss']
})
export class WifiConnectionComponent implements OnInit {
  isConnected: boolean = false;
  status!: string;
  currentState!: ConnectionState;
  subscription = new Subscription();
  connectionService = inject(ConnectionService);
  snack = inject(SnackService);
  constructor() {}

  // ngOnInit(): void {
  //   this.connectionService.monitor().subscribe(isConnected => {
  //     this.isConnected = isConnected;
  //     if (this.isConnected) {
  //       this.snack.showSnack('COMMON.InternetConnected','success')
  //     }
  //     else {
  //       this.snack.showSnack('COMMON.PleaseInternet','error')
  //     }
  //   })
  // }



  ngOnInit(): void {
    this.subscription.add(
      this.connectionService.monitor().pipe(
        tap((newState: ConnectionState) => {
          this.currentState = newState;
          if (newState.hasNetworkConnection || newState.hasInternetAccess) {
            this.isConnected = true;
            this.status = 'ONLINE';
            //this.snack.showSnack('COMMON.InternetConnected','success')
          } else {
            this.status = 'OFFLINE';
            this.isConnected = false;
            this.snack.showSnack('COMMON.PleaseInternet','error')
          }
        })
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
