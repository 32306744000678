import {Component} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'no-data-found',
  standalone: true,
  imports: [
    TranslatePipe,
    NgOptimizedImage
  ],
  templateUrl: './no-data-found.component.html'
})
export class NoDataFoundComponent {
  constructor() {
  }

}
