import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {MatCard} from '@angular/material/card';
import {NgOptimizedImage} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {TranslatePipe} from '@ngx-translate/core';
import {MatIconButton} from '@angular/material/button';

@Component({
  selector: 'pos-item',
  standalone: true,
  imports: [
    MatCard,
    NgOptimizedImage,
    MatIcon,
    TranslatePipe,
    MatIconButton
  ],
  templateUrl: './pos-item.component.html',
  styleUrl:'pos-item.component.scss'
})
export class PosItemComponent {
  @Input() itemName: string;
  @Input() imagePath: string;
  @Input() itemCount: any;
  @Input() showCountAction: boolean = false;
  @Input() showBadge: boolean = false;
  @Input() badgeLabel: any;
  @Output() decrement = new EventEmitter();
  @Output() increment = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() showDetails = new EventEmitter();


  baseImageUrl = environment.baseImageUrl;

  emitShowDetails() {
    this.showDetails.emit();
  }
  emitDelete() {
    this.delete.emit();
  }
  emitIncrement() {
    this.increment.emit();
  }
  emitDecrement() {
    this.decrement.emit();
  }

}
