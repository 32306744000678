<mat-card style="height: 100%;cursor: pointer" class="m-b-0" >
  <div class="d-flex align-items-center justify-content-between bg-primary-100 p-8">
    <div class="num">
      <p class="number m-b-0 m-t-0">{{ mainHeader }}</p>
      @if (createdDate) {
        <p
          class="f-s-10 m-b-0 m-t-0">{{ createdDate + ' UTC' | date:'medium' }}</p>
      }
      @if (subTitle) {
        <p
          class="f-s-10 m-b-0 m-t-0">{{ subTitle }}</p>
      }

    </div>
    <div class="spacer"></div>
    @if (status) {
      <span
        [ngStyle]="{'background-color': status['color']}"
        class="rounded f-w-600 text-white p-6 p-y-4 m-x-6 f-s-12">
              {{ status.name }}
            </span>
    }
      <div class="d-flex flex-row align-items-center">
        <ng-content ></ng-content>
      </div>
  </div>
  <div class="p-12" [routerLink]="enableRoute ? routeLink : null" (click)="emitAction()">
    @if (showImage) {
      <div class="d-flex align-items-center justify-content-center">
        <img [ngSrc]="imagePath ? baseImageUrl +  imagePath : './assets/images/item.png'"
             width="100" height="100" alt=""
        >
      </div>
    }

    @for (row of details; track row.key;let first = $first) {
      @if (!first) {
        <mat-divider></mat-divider>
      }
      <div class="d-flex flex-row p-y-4 p-x-10">
        <p class="f-w-700">{{ row.key| translate }}&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p>{{ row.value | truncate:[30, '...'] }}</p>
      </div>
    }
  </div>
  @if (bodyActionLabel) {
    <div class="d-flex align-items-center justify-content-center p-y-6 p-x-10">
      <button mat-stroked-button color="primary" (click)="emitBodyAction()">{{ bodyActionLabel |translate}}</button>
    </div>
  }
  @if (noteLabel) {
    <div class="d-flex align-items-center justify-content-center p-y-6 p-x-10">
      <p  class="rounded b-1 f-w-600 p-x-16 p-y-4 f-s-12" >{{ noteLabel |translate}}</p>
    </div>
  }

</mat-card>


