<div class="dialog-container" [dir]="direction">
<h1 mat-dialog-title
    sytle="text-align: center"
>
{{header ? (header| translate) : 'COMMON.CONFIRM_DELETE_HEADER' | translate}}
</h1>
<div mat-dialog-content style="min-height: 40px"> {{body ? (body| translate) : 'COMMON.CONFIRM_DELETE_BODY' | translate}} </div>
  <div *ngIf="ordersCount" class="flex-print">
    <span *ngIf="countOfOrders" class="span-count">{{ ordersCount +' : ' + countOfOrders }}</span>
  </div>
<div class="p-x-24">  <mat-form-field  *ngIf="print" appearance="outline">
  <mat-label>{{'COMMON.SAFE_REMAIN_BALANCE' | translate}}</mat-label>
  <input matInput [formControl]="remainBalanceControl" type="number" min="0">
</mat-form-field></div>
<div mat-dialog-actions align="end">
  <button *ngIf="!hasInvoice" mat-stroked-button  [mat-dialog-close]="false" >{{'COMMON.CANCEL'|translate}}</button>
  <button *ngIf="hasInvoice" mat-stroked-button (click)="hasNoInv()" >
    {{no ? (no| translate) : 'COMMON.CANCEL' | translate}}
  </button>
  <button *ngIf="!print" mat-flat-button color="primary"   [mat-dialog-close]="true">
    {{yes ? (yes| translate) : 'COMMON.CONFIRM' | translate}}
   </button>
  <button *ngIf="print" mat-flat-button color="primary"   (click)="save(true,false)">
    {{'COMMON.CONFIRM' |translate}}
  </button>

  <button *ngIf="print" mat-flat-button color="primary"   (click)="save(true,true)"

  > {{'COMMON.CONFIRM_PRINT' |translate}}
    <!-- <mat-icon>delete</mat-icon> -->
  </button>
</div>
</div>
