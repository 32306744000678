@if (webcamImage !== null || data.event || croppedImage) {
  <div style="display: flex;align-items: center;justify-content: space-between">
    <div style="display:flex;justify-content: flex-start">
      <h2 mat-dialog-title>{{"COMMON.CROP_AND_EDIT" | translate}}</h2>
    </div>
    <div style="display:flex;justify-content: flex-end">
      <button (click)="close()" mat-icon-button color="warn" style="">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
}


<div  class="cam" [ngClass]="{'camera':data.type === 'camera' && webcamImage === null,'cam':webcamImage !== null || data.event || croppedImage}">

  <div *ngIf="data.type === 'camera' && webcamImage === null">
      <app-camera (pictureTaken)="handleImage($event)"></app-camera>
  </div>
  <div *ngIf="webcamImage !== null || data.event || croppedImage">
    <mat-dialog-content class="mat-typography" style="max-width: 700px">
      <mat-checkbox [checked]="compress" (change)="CheckCompressImage($event)">
        <span class="fileText">{{"COMMON.COMPRESS" | translate}}</span>
      </mat-checkbox>
      <div class="row">
        <div class="col-md-8 col-sm-8">
          <image-cropper style="margin-right:-14px;"
                         [imageChangedEvent]="imageChangedEvent"
                         [imageBase64]="imageBase64"
                         [maintainAspectRatio]="false"
                         [aspectRatio]="9 / 16"
                         [resizeToWidth]="90"
                         format="png"
                         (imageCropped)="imageCropped($event)"
                         (imageLoaded)="imageLoaded()"
                         (cropperReady)="cropperReady()"
                         (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>
        <div class="col-md-4 col-sm-4"><img [src]="croppedImage"/></div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" style="margin-bottom: 4px;">
      <button class="btn no-space" color="primary" mat-raised-button (click)="cropAndSave()">
        <span class="fileText">{{"COMMON.CROP_AND_SAVE" | translate}} </span>
      </button>
      <button class="btn" mat-raised-button color="accent" (click)="saveOnly()">
        <span class="fileText">{{"COMMON.SAVE_ONLY" | translate}} </span>
      </button>
    </mat-dialog-actions>
  </div>
</div>


