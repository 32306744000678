import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {WebcamImage} from 'ngx-webcam';

@Component({
  selector: 'app-image-dialog-processing',
  templateUrl: './image-dialog-processing.component.html',
  styleUrls: ['./image-dialog-processing.component.scss']
})
export class ImageDialogProcessingComponent implements OnInit {

  direction: string;
  imageChangedEvent: any = null;
  croppedImage: any = null;
  isLoaded: boolean = false;
  imageBase64: any = null;
  compress: boolean = true;
  public webcamImage: WebcamImage = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<ImageDialogProcessingComponent>) {
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('LANG');
   // this.direction = lang === 'ar' ? 'rtl' : 'ltr';
    if (this.data) {
      this.isLoaded = true;
      this.fileChangeEvent(this.data.event);
    }
  }

  saveOnly(): void {
    this.webcamImage ?
      this.dialogRef.close({img: this.webcamImage.imageAsDataUrl, compress: this.compress})
      : this.dialogRef.close({compress: this.compress});

  }

  close() {
    this.dialogRef.close();
  }

  cropAndSave() {
    this.dialogRef.close({img: this.croppedImage, compress: this.compress});
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    //console.log('Image loaded');
  }

  cropperReady() {
    //console.log('Cropper ready');
  }

  loadImageFailed() {
    //console.log('Load failed');
  }

  CheckCompressImage(event) {
    if (event.checked) {
      this.compress = true;
    } else {
      this.compress = false;
    }
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    if (this.webcamImage) {
      this.imageBase64 = this.webcamImage.imageAsDataUrl;
    }
  }
}
