<!--<div class="language-button">-->
<!--  <button mat-icon-button [matMenuTriggerFor]="languageMenu">-->
<!--    <span style="font-weight: bold;font-size: 15px" *ngIf="_translate.currentLang === 'ar'"-->
<!--      >AR</span-->
<!--    >-->
<!--    <span style="font-weight: bold;font-size: 15px" *ngIf="_translate.currentLang === 'en'"-->
<!--      >EN</span-->
<!--    >-->
<!--  </button>-->

<!--</div>-->



<button
  style="margin: 0 2px"
  mat-mini-fab
  color="inherit"
  [matMenuTriggerFor]="languageMenu"
  class="d-flex justify-content-center"
>
  @if (_translate.currentLang === 'ar') {
    <span class="f-w-600">Ar</span>
<!--    <i-->
<!--      class="iconify icon-24 d-flex"-->
<!--      data-icon="twemoji:flag-saudi-arabia"-->
<!--    ></i>-->
  } @else {
    <span class="f-w-600">En</span>
<!--    <i-->
<!--      class="iconify icon-22 d-flex"-->
<!--      data-icon="twemoji:flag-united-states"-->
<!--    ></i>-->
  }

</button>
<mat-menu #languageMenu="matMenu">
  <button
    *ngIf="_translate.currentLang !== 'ar'"
    mat-menu-item
    (click)="changeLanguage('ar')"
  >
    <span style="margin-left: 28px; font-weight: bold">AR</span>
  </button>
  <button
    *ngIf="_translate.currentLang !== 'en'"
    mat-menu-item
    (click)="changeLanguage('en')"
  >
    <span style="margin-right: 28px; font-weight: bold">EN</span>
  </button>
</mat-menu>
