import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DecimalPipe, NgStyle} from '@angular/common';
import {TranslatePipe} from '@ngx-translate/core';
import {Constant} from '../../../../shared/constant/constant';

@Component({
  selector: 'dashboard-card',
  standalone: true,
  imports: [
    DecimalPipe,
    TranslatePipe,
    NgStyle
  ],
  templateUrl: './dashboard-card.component.html',
  styleUrl: './dashboard-card.component.scss'
})
export class DashboardCardComponent {
  @Input() label: string = '';
  @Input() value: number = 0;
  @Input() index: number = 0;
  @Input() hasAction: boolean = false;
  @Output() action: EventEmitter<any> = new EventEmitter();
  constructor(private constant:Constant) {
  }
  get cardColor(): string {
    return this.constant.colors[this.index % this.constant.colors.length];
  }

  emitAction() {
    this.action.emit();
  }
}
