<mat-card style="cursor: pointer" class="p-12" [routerLink]="routeLink ? routeLink :'./' + id">
  <div class="d-flex justify-content-between p-y-6">
    <div>
      <div>
        <span class="key f-w-700">{{ 'PRINTER.CREATED_DATE'|translate }}</span>&nbsp;
        <span
          class="value">{{ createdDate ? (createdDate + ' UTC' | date:'medium') : '' }}</span>
      </div>
    </div>
    <div>
      <div>
        <span class="key f-w-700">{{ numberLabel |translate }}</span>&nbsp;
        {{ number }}
      </div>
    </div>

    <span
      [ngStyle]="{'background-color': status['color']}"
      class="rounded f-w-600 text-white p-6 p-y-4 f-s-12">
              {{ status.name }}
            </span>
  </div>
  <mat-divider></mat-divider>
  <div class="row p-x-4">
    @for (row of details; track row.key) {
      <div class="col-12 col-sm-6 col-md-4 d-flex flex-row p-y-4">
        <p class="f-w-700">{{ row.key| translate }}&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p>{{ row.value }}</p>
      </div>
    }
    <ng-content></ng-content>
  </div>
</mat-card>
