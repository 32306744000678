import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MenuItemButtonComponent} from '../../buttons/menu-item-button/menu-item-button.component';
import {MenuItemCancelButtonComponent} from '../../buttons/menu-item-cancel-button/menu-item-cancel-button.component';
import {MatMenu} from '@angular/material/menu';
import {MenuItemInterface} from '../../../../core/types';

@Component({
  selector: 'screen-menu',
  standalone: true,
  imports: [
    MenuItemButtonComponent,
    MenuItemCancelButtonComponent,
    MatMenu
  ],
  templateUrl: './screen-menu.component.html'
})
export class ScreenMenuComponent implements AfterViewInit {
  @Input() menuItems: MenuItemInterface[] = [];
  @Input() cancelRouteLink: string;
  @Input() disabled: boolean = false;
  @Output() menuReady = new EventEmitter<MatMenu>();
  @ViewChild('actionsMenu') actionsMenu: MatMenu;

  constructor() {
  }

  ngAfterViewInit() {
    this.menuReady.emit(this.actionsMenu);
  }
}
