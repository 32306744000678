import {TranslateService} from '@ngx-translate/core';
import {Component, ElementRef, EventEmitter, inject, OnInit, Output, ViewChild} from '@angular/core';
import {BaseService} from '../../service/base.service';
import {CustomerProgressDialogComponent} from './customer-progress-dialog/customer-progress-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {EmployeeCheckInOutComponent} from '../../../pages/human-resources/employee-check-in-out/employee-check-in-out.component';
import {Router} from '@angular/router';
import {TourService} from 'ngx-ui-tour-md-menu';
import {GuidelineService} from '../../service/guideline.service';
import {UserTypeService} from '../../../core/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  public width: number;
  public screenWidth: any;
  empImage: string;
  headerStyle = this._base.headerStyle;
  prodHeaderStyle = this._base.prodHeaderStyle;
  production = this._base.production;
  sidenav: boolean = true;
  userTypes = JSON.parse(localStorage.getItem('CURRENT_USER')).userTypes?.map((userType) => userType.code);
  currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
  showAttendanceIcon: boolean = this.userTypes.includes('EMP') || this.userTypes.includes('SUPERVISOR') || this.userTypes.includes('MANAGER') || this.userTypes.includes('CUST');
  public tourService = inject(TourService);

  calcHeader() {
    const _sideNav = document.getElementById('pagesSideNavigation');
    const sideNav: HTMLElement = _sideNav as HTMLElement;
    this.width = screen.width - sideNav.clientWidth;
  }

  constructor(public _translate: TranslateService,
              private _base: BaseService,
              public guidelineService: GuidelineService,
              private dialog: MatDialog,
              private router: Router,
              public userType:UserTypeService
              ) {
    this.empImage = this.production ? 'assets/images/icons/finger_prod.png' : 'assets/images/icons/finger_dev.png';
  }
  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    if(this.currentUser.showTutorialGuide === true) {
      this.guidelineService.start();
    }
  }

  @ViewChild('fixedHeader') fixedHeader: ElementRef;

  ngAfterViewInit() {
    // this.width = this.fixedHeader.nativeElement.offsetWidth - 217;
    this.calcHeader();
  }


  toggleSideNav() {
    this.toggle.emit();
    this.calcHeader();
    this.sidenav = !this.sidenav;
  }

  openCustomerProgressDialog() {
    const dialogRef = this.dialog.open(CustomerProgressDialogComponent, {}).addPanelClass('progress_dialog');
  }

  openAttendancePanel() {
    this.dialog.open(EmployeeCheckInOutComponent).addPanelClass('attendance-dialog-container');
  }

  openSub() {
    this.router.navigate(['/pages/invoices/sub-prices']);
  }
}
