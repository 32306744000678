import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation} from '@angular/core';
import {DatePipe, NgOptimizedImage, NgStyle} from '@angular/common';
import {MatCard} from '@angular/material/card';
import {MatDivider} from '@angular/material/divider';
import {TranslatePipe} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {MatButton} from '@angular/material/button';
import {TruncatePipe} from '../../../../core/pipes';

@Component({
  selector: 'lookup-card',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    DatePipe,
    MatCard,
    MatDivider,
    TranslatePipe,
    RouterLink,
    NgOptimizedImage,
    MatButton,
    NgStyle,
    TruncatePipe
  ],
  templateUrl: './lookup-card.component.html',
  styleUrl: './lookup-card.component.scss'
})
export class LookupCardComponent  {
  @Input() id!: string;
  @Input() name!: string;
  @Input() status: any;
  @Input() mainHeader!: string;
  @Input() showImage: boolean =  false;
  @Input() imagePath: string;
  @Input() createdDate!: string;
  @Input() details!: { key: string, value: any }[];
  @Input() subTitle: string;
  @Input() enableRoute: boolean = false;
  @Input() routeLink: string;
  @Input() bodyActionLabel: string;
  @Input() noteLabel: string;
  @Output() action = new EventEmitter();
  @Output() bodyAction = new EventEmitter();
  baseImageUrl = environment.baseImageUrl;
  @ContentChild(TemplateRef) lookupContent: TemplateRef<any>;
  emitAction() {
    this.action.emit();
  }
  emitBodyAction() {
    this.bodyAction.emit();
  }
}
