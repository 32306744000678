<mat-menu #actionsMenu="matMenu">
  @for (item of menuItems ; track item.name) {
    @if (item.condition()) {
      <menu-button
        [disabled]="disabled"
        [name]="item.name"
        (action)="item.action()"
        [hasDivider]="item.hasDivider">
      </menu-button>
    }
  }
  <cancel-button [routeLink]="cancelRouteLink"></cancel-button>
</mat-menu>
