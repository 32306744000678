import {Component, Input} from '@angular/core';
import {MatMenuItem} from '@angular/material/menu';
import {RouterLink} from '@angular/router';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'cancel-button',
  standalone: true,
  imports: [
    MatMenuItem,
    RouterLink,
    TranslatePipe
  ],
  templateUrl: './menu-item-cancel-button.component.html'
})
export class MenuItemCancelButtonComponent {
  @Input() name:string = 'COMMON.CANCEL';
  @Input() routeLink:string;
}
