<div class="p-16 d-flex align-items-center justify-content-between" [dir]="direction">
  <h2 mat-dialog-title>{{ title| translate }}</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon color="warn">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography"  [style.min-width.px]="mobileQuery.matches ? 0 : dialogContentMinWidth" [dir]="direction">
  <ng-content></ng-content>
</mat-dialog-content>

<mat-dialog-actions class="p-24 p-t-0 d-flex align-items-center" [dir]="direction">
  @if (save_action_text) {
    <button
      mat-flat-button
      [disabled]="disableCondition"
      (click)="onSubmit()"
      color="primary">
      {{ save_action_text | translate }}
    </button>
  }
</mat-dialog-actions>
