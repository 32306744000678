import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HumanResourcesService} from '../../../pages/human-resources/service/human-resources.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-shared-dialog',
  templateUrl: './shared-dialog.component.html',
  styleUrls: ['./shared-dialog.component.scss']
})
export class SharedDialogComponent implements OnInit {

  constructor(
    private sharedDialogRef: MatDialogRef<SharedDialogComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public sharedData: any

  ) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.sharedDialogRef.close();
  }

}
