import {BaseService} from './../../../service/base.service';
import {Router} from '@angular/router';
import {AuthService} from './../../../../auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';

export interface MenuItem {
  icon: string;
  translate: string;
  type: string;
  target: string;
}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  userImagePath: String;
  personImage: string;
  production = this._base.production;
  billIconColor = this._base.billIconColor;
  backgroundColor = this._base.headerStyle['background-color'];
  // userId = JSON.parse(localStorage.getItem('CURRENT_USER'))['userId'];
  loader:boolean = false;
  constructor(
    public _translate: TranslateService,
    private _auth: AuthService,
    private _router: Router,
    private _base: BaseService
  ) {
    this.personImage = this.production ?  'assets/images/person.png' : 'assets/images/person_dev.png';
    _base.updateUserImage();
    this.userImagePath = this._base.userImagePath;
  }

  ngOnInit(): void {

  }

  logout(){
    this._auth.logout();
  }

  refresh(){
    localStorage.removeItem('CONFIG');
    this._auth.configObservable().subscribe((res) => {
      if (res?.status !== 304 && res?.body?.success) {
        this.loader = true;
        this._auth.config.next(res.body.data);
        localStorage.setItem('CONFIG', JSON.stringify(res.body.data));
      }

    },(err) => {
      console.log(err);
    },() => {
      setTimeout(() => {
        this.loader = false
      },500)
    })
    localStorage.removeItem('CUSTOMER_BRANCHES');

    this._auth.getCustomerBranches().subscribe((res) => {
      if (res.success) {
        this.loader = true;
        localStorage.setItem('CUSTOMER_BRANCHES', JSON.stringify(res.data));
      }

    },(err) => {
      console.log(err);
    },() => {
      setTimeout(() => {
        this.loader = false
      },500)
    })
  }
}
