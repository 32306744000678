<div  mat-dialog-content class="release_div"  [dir]="_translate.currentLang == 'ar' ? 'rtl' : 'ltr'">
  <div class="row" style="justify-content: space-between">
    <p class="release_header">{{'COMMON.RELEASE_NOTES' | translate}}</p>
    <p class="release">1.1.1</p>
  </div>

  <div>
    <p class="scope">{{'COMMON.SCOPE_OF_WORK' | translate}}</p>
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel style="min-width: 320px;" *ngFor="let module of modules" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 14px;">
            <mat-icon>{{module.fontIcon}}</mat-icon>
            <p>{{module.name}}</p>
          </mat-panel-title>
          <mat-panel-description style="font-size: 12px">
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div style="padding: 5px 15px;margin: 10px 0">
          <p [innerHTML]="module.versionDesc"></p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

</div>
