export class SearchConfiguration {
    [x: string]: any;
    constructor(
        fields?: [
            {
                fieldType: string,
                type: string,
                name: string,
                placeholder:string,
                options: [],
                optionID:string,
                optionName:string
            }
        ]
    ) {

    }


}
