import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {MatIcon} from '@angular/material/icon';
import {LowerCasePipe} from '@angular/common';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'pop-up-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    TranslatePipe,
    MatIconButton,
    MatDialogClose,
    MatIcon,
    LowerCasePipe
  ],
  templateUrl: './pop-up-dialog.component.html',
  styleUrl: './pop-up-dialog.component.scss'
})
export class PopUpDialogComponent {
  @Input() title: string;
  @Input() save_action_text: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  dialogContentMinWidth: number = 460;
  @Input() disableCondition: boolean = false;
  @Output() submitDialog = new EventEmitter<void>();
  @Output() closeDialog = new EventEmitter<void>();

  constructor(private _translate : TranslateService,
              private _changeDetectorRef: ChangeDetectorRef,
              private _media: MediaMatcher,) {
    this.mobileQuery = this._media.matchMedia('(max-width: 490px)');
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  onSubmit() {
    this.submitDialog.emit();
  }

  onCancel() {
    this.closeDialog.emit();
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }
  get direction() {return this._translate.currentLang === 'ar' ? 'rtl' : 'ltr' }}

