<mat-form-field appearance="outline" class="w-100" color="primary">
  <mat-select [panelWidth]="isMobileView() || isWebView() ? '412px' : '300px'" (selectionChange)="onChange($event)" [value]="branchConfig" [formControl]="itemMultiCtrl" [placeholder]="outlineLabel"
              #singleSelect>
    <mat-option>
      <ngx-mat-select-search [formControl]="branchFilterCtrl" [placeholderLabel]="'COMMON.SEARCH' | translate"
                             [noEntriesFoundLabel]="'COMMON.NO_DATA_FOUND' | translate"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let branch of branches | async" [value]="branch">
      @if (isMobileView() || isWebView()) {
        {{branch.branchName}}
      } @else if (isSmallMobileView()) {
        <span>{{branch.branchName | truncate:[40,'...']}}</span>
      }

    </mat-option>
  </mat-select>
</mat-form-field>
