import {Component, Input, OnInit} from '@angular/core';
import {MatMiniFabButton} from '@angular/material/button';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
  selector: 'more-vert-button',
  templateUrl: './more-vert-button.component.html',
  standalone: true,
  imports: [
    MatMiniFabButton,
    MatMenuTrigger
  ],
})
export class MoreVertButtonComponent {
  @Input() actionsMenu:any;
}
