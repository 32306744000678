import { Component ,Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {MatSuffix} from '@angular/material/form-field';
import {MatIconButton, MatMiniFabButton} from '@angular/material/button';


@Component({
  selector: 'app-guidance',
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatSuffix,
    MatIconButton,
    MatMiniFabButton
  ],
  templateUrl: './guidance.component.html',
  styleUrl: './guidance.component.scss'
})
export class GuidanceComponent {
  @Input() label: string;
}
