<button
  mat-mini-fab
  color="primary"
  [matMenuTriggerFor]="actionsMenu"
  class="d-flex justify-content-center"
>
  <i
    class="iconify icon-20 d-flex"
    data-icon="ic:baseline-more-vert"
  ></i>
</button>
