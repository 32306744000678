export enum UserTypesRolesEnum {
  admin = 'ADMIN',
  customer = 'CUST',
  consumer = 'CONS',
  accountManager = 'ACCM',
  employee = 'EMP',
  supplier = 'SUPPLIER',
  backoffice = 'BACKOF',
  supervisor = 'SUPERVISOR',
}
