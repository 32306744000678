import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatMenuItem} from '@angular/material/menu';
import {TranslatePipe} from '@ngx-translate/core';
import {MatDivider} from '@angular/material/divider';

@Component({
  selector: 'menu-button',
  standalone: true,
  imports: [
    MatMenuItem,
    TranslatePipe,
    MatDivider
  ],
  templateUrl: './menu-item-button.component.html'
})
export class MenuItemButtonComponent {
  @Input() name:string = 'COMMON.SAVE';
  @Output() action = new EventEmitter();
  @Input() disabled: boolean = false;
  @Input() hasDivider: boolean = false;

  emitAction() {
    this.action.emit();
  }
}
